import { getNumberInBounds } from "../../../../core/utils";
import { getUTCdate } from "../../../../core/utils";

export function sortByDate(
  data: { x: Date; y: number }[]
): { x: Date; y: number }[] {
  return data.sort((a, b) => {
    return a.x > b.x ? 1 : -1;
  });
}

export function convertToUtc(
  data: { x: Date; y: number }[]
): { x: Date; y: number }[] {
  return data.map((datum) => {
    return {
      x: getUTCdate(datum.x),
      y: datum.y,
    };
  });
}

export function getYearsToFetch({
  start,
  end,
}: {
  start: Date;
  end: Date;
}): number[] {
  if (end < start) return [];
  const startYear = getStartYear(start);
  const endYear = getEndYear(end);
  const years = [];
  for (let i = startYear; i <= endYear; i++) {
    years.push(i);
  }
  return years;
}

function getStartYear(date: Date): number {
  const year = date.getUTCFullYear();
  return Math.max(year, 2021);
}

function getEndYear(date: Date): number {
  const year = date.getUTCFullYear();
  const currYear = new Date().getUTCFullYear();
  return Math.min(year, currYear);
}

export function getConversionRates({
  orders,
  impressions,
}: {
  orders: { date: string; val: number }[];
  impressions: { date: string; val: number }[];
}): { date: string; val: number }[] {
  function calculateConversionRate({
    order,
    impression,
  }: {
    order: { date: string; val: number };
    impression: { date: string; val: number };
  }) {
    const conversionRate = (order.val / impression.val) * 100;
    return {
      date: order.date,
      val: getNumberInBounds({
        num: conversionRate,
        bounds: { lower: 0, upper: 100 },
      }),
    };
  }
  if (orders.length < impressions.length) {
    return orders.map((order, i) => {
      const impression = impressions[i];
      if (impression === undefined) {
        throw new Error(`No impression at index ${i}`);
      }
      return calculateConversionRate({ order, impression });
    });
  } else {
    return impressions.map((impression, i) => {
      const order = orders[i];
      if (order === undefined) {
        throw new Error(`No order at index ${i}`);
      }
      return calculateConversionRate({ order, impression });
    });
  }
}

export async function mergeAsyncCalls<T>(
  calls: (() => Promise<T>)[]
): Promise<T[]> {
  return await Promise.all(calls.map((call) => call()));
}

type GenericStringIndexableObject<T> = {
  [key: string]: T;
};

export function combineObjects<T>(
  objects: GenericStringIndexableObject<T>[]
): GenericStringIndexableObject<T> {
  const baseObject: { [key: string]: T } = {};
  return Object.assign(baseObject, ...objects);
}

export function graphDataFromEventValues(eventValues: {
  [key: string]: number;
}): { x: Date; y: number }[] {
  return Object.entries(eventValues)
    .map(([dateStr, value]) => {
      const date = new Date(dateStr);
      if (isNaN(date.getTime())) return null;
      return {
        x: date,
        y: value,
      };
    })
    .filter((val) => val !== null) as { x: Date; y: number }[];
}

export function getGraphPointsWithinDateRange({
  points,
  range: { start, end },
}: {
  points: { x: Date; y: number }[];
  range: { start: Date; end: Date };
}): { x: Date; y: number }[] {
  return points.filter((point) => {
    if (daysApart(point.x, start) < 0) return false;
    if (daysApart(point.x, end) > 0) return false;
    return true;
  });
}

export function daysApart(date1: Date, date2: Date): number {
  const date1Utc = Date.UTC(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  );
  const date2Utc = Date.UTC(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  );
  return (date1Utc - date2Utc) / 86400000;
}
