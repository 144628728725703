import styles from "../Chart.module.css";

export const StartDateInput = (props: DateInputProps) => {
  return (
    <div className={styles.startDate}>
      <input
        type="text"
        name="start"
        className={styles.dateInput}
        placeholder="Start Date"
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};

export const EndDateInput = (props: DateInputProps) => {
  return (
    <div className={styles.endDate}>
      <input
        type="text"
        name="end"
        className={styles.dateInput}
        placeholder="End Date"
        onChange={props.onChange}
        onBlur={props.onBlur}
        value={props.value}
        onKeyDown={props.onKeyDown}
      />
    </div>
  );
};

interface DateInputProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur: (e: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
  value: string;
}
