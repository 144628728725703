import styles from "../Chart.module.css";
import React, { useEffect, useState, useCallback } from "react";
import { DatePicker } from "@shopify/polaris";
import { getDateRangeFromSelection, getPreviousMonthAndYear } from "./utils";
import { DateRangeDropdown, DateRangeSelections } from "./dropdown";
import { EndDateInput, StartDateInput } from "./inputs";

export const DateSelectorCard = ({
  dateRangeText,
  setDateRangeText,
  selectedDates,
  setSelectedDates,
  onDateClick,
  calendarFocusDate,
  setCalendarFocusDate,
}: DateSelectorCardProps) => {
  const [tempDateString, setTempDateString] = useState({
    start: selectedDates.start.toISOString().slice(0, 10),
    end: selectedDates.end.toISOString().slice(0, 10),
  });

  useEffect(() => {
    setTempDateString({
      start: selectedDates.start.toISOString().slice(0, 10),
      end: selectedDates.end.toISOString().slice(0, 10),
    });
  }, [selectedDates]);

  const handleMonthChange = useCallback(
    (month, year) => setCalendarFocusDate({ month, year }),
    []
  );

  function handleStartDateInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setTempDateString((prevValue) => {
      return {
        ...prevValue,
        start: value,
      };
    });
  }

  function handleEndDateInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;
    setTempDateString((prevValue) => {
      return {
        ...prevValue,
        end: value,
      };
    });
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.currentTarget.blur();
    }
  }

  function handleStartBlur(e: React.FocusEvent<HTMLInputElement>) {
    let date = getDateFromInput(e.target.value);
    if (isInvalidDate(date)) return;

    if (date.getTime() > selectedDates.end.getTime()) {
      date = selectedDates.end;
    }
    if (date.getTime() !== selectedDates.start.getTime()) {
      setDateRangeText(DateRangeSelections.custom);
    }

    setCalendarFocusDate({ month: date.getMonth(), year: date.getFullYear() });
    setSelectedDates((prevValue) => {
      return {
        ...prevValue,
        start: date,
      };
    });
    setTempDateString((prevValue) => {
      return {
        ...prevValue,
        start: date.toISOString().slice(0, 10),
      };
    });
  }

  function handleEndBlur(e: React.FocusEvent<HTMLInputElement>) {
    let date = getDateFromInput(e.target.value);
    if (isInvalidDate(date)) return;

    if (date.getTime() < selectedDates.start.getTime()) {
      date = selectedDates.start;
    }
    if (date.getTime() !== selectedDates.end.getTime()) {
      setDateRangeText(DateRangeSelections.custom);
    }

    setCalendarFocusDate({ month: date.getMonth(), year: date.getFullYear() });
    setSelectedDates((prevValue) => {
      return {
        ...prevValue,
        end: date,
      };
    });
    setTempDateString((prevValue) => {
      return {
        ...prevValue,
        end: date.toISOString().slice(0, 10),
      };
    });
  }

  function handleDropdownChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const selection = e.target.value;
    setDateRangeText(selection);
    const selectedDateRange = getDateRangeFromSelection(selection);
    if (selectedDateRange !== null) {
      setSelectedDates(selectedDateRange);
      setCalendarFocusDate(getPreviousMonthAndYear(selectedDateRange.end));
    }
  }

  return (
    <div>
      <DateRangeDropdown
        onChange={handleDropdownChange}
        value={dateRangeText}
      />
      <div className={styles.dateRangeInput}>
        <StartDateInput
          onChange={handleStartDateInputChange}
          onBlur={handleStartBlur}
          value={tempDateString.start}
          onKeyDown={handleKeyDown}
        />
        <EndDateInput
          onChange={handleEndDateInputChange}
          onBlur={handleEndBlur}
          value={tempDateString.end}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className={styles.datePicker}>
        <DatePicker
          month={calendarFocusDate.month}
          year={calendarFocusDate.year}
          onChange={onDateClick}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          multiMonth
          allowRange
        />
      </div>
    </div>
  );
};

function getDateFromInput(val: string): Date {
  const localTimeValue = val + "T00:00";
  const date = new Date(Date.parse(localTimeValue));
  return date;
}

function isInvalidDate(date: Date): boolean {
  return isNaN(date.getTime());
}

interface DateSelectorCardProps {
  selectedDates: { start: Date; end: Date };
  setSelectedDates: React.Dispatch<
    React.SetStateAction<{
      start: Date;
      end: Date;
    }>
  >;
  onDateClick: (date: { start: Date; end: Date }) => void;
  dateRangeText: string;
  setDateRangeText: (newVal: string) => void;
  calendarFocusDate: { month: number; year: number };
  setCalendarFocusDate: React.Dispatch<
    React.SetStateAction<{
      month: number;
      year: number;
    }>
  >;
}
