import { getCompositeEventValue } from "../../../../core/firebase/firestore/tracker/client";
import { ChartTabKey } from "../types";
import type firebase from "firebase/app";
import {
  convertToUtc,
  getGraphPointsWithinDateRange,
  getYearsToFetch,
  graphDataFromEventValues,
  sortByDate,
} from "./fetchUtils";
import { calculateConversionRate } from "core/conversionRate";
import { AnalyticsEventKeys } from "core/firebase/firestore/tracker/shared/constants";
import { getUTCdate } from "core/utils";

export async function fetchGraphData({
  tab,
  firestore,
  shopId,
  dateRange,
}: {
  tab: ChartTabKey;
  firestore: firebase.firestore.Firestore;
  shopId: string;
  dateRange: { start: Date; end: Date };
}): Promise<{ x: Date; y: number }[]> {
  switch (tab) {
    case ChartTabKey.impressions:
      return fetchImpressions({ shopId, firestore, dateRange });
    case ChartTabKey.conversionRate:
      return fetchConversionRate({ shopId, firestore, dateRange });
    case ChartTabKey.orders:
      return fetchOrdersCount({ shopId, firestore, dateRange });
    case ChartTabKey.revenue:
      return fetchRevenue({ shopId, firestore, dateRange });
  }
}

async function fetchImpressions(args: FetchStatsArgs) {
  return fetchStatByKey({
    ...args,
    key: AnalyticsEventKeys.impressions,
  });
}

async function fetchOrdersCount(args: FetchStatsArgs) {
  return fetchStatByKey({
    ...args,
    key: AnalyticsEventKeys.ordersCount,
  });
}

async function fetchRevenue(args: FetchStatsArgs) {
  return fetchStatByKey({
    ...args,
    key: AnalyticsEventKeys.revenue,
  });
}

async function fetchOrderCountsAndImpressions(
  args: FetchStatsArgs
): Promise<{ impressions: Datapoint[]; ordersCounts: Datapoint[] }> {
  const ordersCountsPromise = fetchOrdersCount(args);
  const impressionsPromise = fetchImpressions(args);
  return {
    impressions: await impressionsPromise,
    ordersCounts: await ordersCountsPromise,
  };
}

async function fetchConversionRate(args: FetchStatsArgs) {
  const { ordersCounts, impressions } = await fetchOrderCountsAndImpressions(
    args
  );
  if (ordersCounts.length !== impressions.length) {
    throw new Error(
      "Orders and impressions are unequal lengths. Unable to calculate conversion rate."
    );
  }
  const shorterLength = Math.min(ordersCounts.length, impressions.length);
  const conversionRates: Datapoint[] = [];
  for (let i = 0; i < shorterLength; i++) {
    const ordersCountPoint = ordersCounts[i];
    const impressionsPoint = impressions[i];
    if (ordersCountPoint === undefined || impressionsPoint === undefined) {
      throw new Error(
        `Unable to index orders count or impressions at index ${i}`
      );
    }
    const conversionRate = calculateConversionRate({
      ordersCount: ordersCountPoint.y,
      impressions: impressionsPoint.y,
    });
    conversionRates.push({ x: ordersCountPoint.x, y: conversionRate });
  }
  return conversionRates;
}

interface Datapoint {
  x: Date;
  y: number;
}

async function fetchStatByKey({
  firestore,
  shopId,
  dateRange,
  key,
}: {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  dateRange: { start: Date; end: Date };
  key: string;
}): Promise<{ x: Date; y: number }[]> {
  const dataByYear = await fetchDataByYear({
    dateRange,
    firestore,
    shopId,
    key,
  });
  const graphPoints = dataByYear.flat();
  return getProcessedGraphData(graphPoints, dateRange);
}

function getProcessedGraphData(
  graphPoints: { x: Date; y: number }[],
  dateRange: { start: Date; end: Date }
) {
  graphPoints = getGraphPointsWithinDateRange({
    points: graphPoints,
    range: dateRange,
  });
  graphPoints = convertToUtc(graphPoints);
  const dataWithFilledMissingVals = fillMissingDatesWithZeros({
    data: graphPoints,
    dateRange: {
      start: getUTCdate(dateRange.start),
      end: getUTCdate(dateRange.end),
    },
  });
  const sortedData = sortByDate(dataWithFilledMissingVals);
  return sortedData;
}

async function fetchDataByYear({
  dateRange,
  firestore,
  shopId,
  key,
}: {
  dateRange: { start: Date; end: Date };
  firestore: firebase.firestore.Firestore;
  shopId: string;
  key: string;
}) {
  const yearsToFetch = getYearsToFetch(dateRange);
  const dataByYear = await Promise.all(
    yearsToFetch.map(async (year) => {
      const data = await getCompositeEventValue({
        firestore,
        identifiers: { shopId, key },
        year,
      });
      if (!data) return [];
      return graphDataFromEventValues(data);
    })
  );
  return dataByYear;
}

function fillMissingDatesWithZeros({
  data,
  dateRange,
}: {
  data: { x: Date; y: number }[];
  dateRange: {
    start: Date;
    end: Date;
  };
}): { x: Date; y: number }[] {
  for (
    let d = new Date(dateRange.start);
    d <= dateRange.end;
    d.setDate(d.getDate() + 1)
  ) {
    const dateCopy = new Date(d);
    if (dateIsNotInData(data, dateCopy)) {
      data.push({ x: dateCopy, y: 0 });
    }
  }
  return data;
}

function dateIsNotInData(data: { x: Date; y: number }[], d: Date) {
  return data.find((datum) => datesAreOnSameDay(d, datum.x)) === undefined;
}

function datesAreOnSameDay(date1: Date, date2: Date) {
  return (
    date1.getUTCFullYear() === date2.getUTCFullYear() &&
    date1.getUTCMonth() === date2.getUTCMonth() &&
    date1.getUTCDate() === date2.getUTCDate()
  );
}

interface FetchStatsArgs {
  firestore: firebase.firestore.Firestore;
  shopId: string;
  dateRange: { start: Date; end: Date };
}
