import styles from "../Chart.module.css";
import React, { useEffect, useState } from "react";
import {
  LineMarkSeries,
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
  Hint,
} from "react-vis";
import LoadingGraph from "./loading";
import { dateStringFromAny } from "../DatePicker/utils";
import { EmptyGraph } from "./empty";

export const Graph = ({ isLoading, data, yLabel, dateRange }: GraphProps) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [hoveredPoint, setHoveredPoint] = useState<{
    x: string | number | Date;
    y: string | number | Date;
  }>();

  useEffect(() => {
    function handleMouseMove() {
      setShowTooltip(false);
    }
    document.addEventListener("mousemove", handleMouseMove);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  if (isLoading) {
    return <LoadingGraph />;
  }
  if (data.length === 0) {
    return <EmptyGraph />;
  }

  const max = Math.max(...data.map((datum) => datum.y), 1);
  return (
    <div className={styles.graphContainer}>
      <div className={styles.yLabel}>{yLabel}</div>
      <FlexibleXYPlot
        xDomain={[dateRange.start, dateRange.end]}
        yDomain={[0, max * 1.05]}
        style={{ overflow: "visible" }}
        xType="time"
      >
        <HorizontalGridLines />
        <XAxis
          tickTotal={7}
          style={{
            text: {
              stroke: "none",
              fill: "#637381",
              fontWeight: 400,
              fontSize: "14px",
            },
          }}
        />
        <YAxis
          style={{
            text: {
              stroke: "none",
              fill: "#637381",
              fontWeight: 400,
              fontSize: "12px",
            },
          }}
          tickFormat={(val) => (Math.round(val) === val ? val.toFixed(0) : "")}
        />
        <LineMarkSeries
          data={data}
          color="#5C6AC4"
          lineStyle={{ strokeWidth: "3px" }}
          markStyle={{ stroke: "white", strokeWidth: "1.5px" }}
          animation="stiff"
          curve={"curveMonotoneX"}
          onValueMouseOver={(datapoint, e) => {
            const event = (e as any).event as React.MouseEvent<
              HTMLElement,
              MouseEvent
            >;
            event.stopPropagation();
            setShowTooltip(true);
            setHoveredPoint({
              x: datapoint.x,
              y: datapoint.y,
            });
          }}
          onValueMouseOut={() => {
            setShowTooltip(false);
          }}
        />
        {showTooltip && (
          <Hint value={hoveredPoint}>
            <div
              style={{
                background: "#EFF0F9",
                color: "#5c6ac4",
                borderRadius: 3,
                padding: "10px",
                pointerEvents: "none",
              }}
            >
              <p>
                <span style={{ fontWeight: 500 }}>Date:</span>{" "}
                {dateStringFromAny(hoveredPoint?.x)}
              </p>
              <p>
                <span style={{ fontWeight: 500 }}>{yLabel}:</span>{" "}
                {hoveredPoint?.y.toLocaleString("en-US", {
                  maximumFractionDigits: 2,
                })}
              </p>
            </div>
          </Hint>
        )}
      </FlexibleXYPlot>
    </div>
  );
};

export interface GraphProps {
  isLoading: boolean;
  data: { x: Date; y: number }[];
  yLabel: string;
  dateRange: {
    start: Date;
    end: Date;
  };
}
