import React from "react";
import styles from "./AppCard.module.scss";

export interface AppCardProps extends React.ComponentProps<"div"> {
  name: string;
  description: string;
  link: string;
  imgSrc: string;
  logoBackgroundColor: string;
  reviewRating: string;
  isCompleted: boolean;
}

const AppCard = (props: AppCardProps) => {
  return (
    <a style={{ textDecoration: "none" }} target="_blank" href={props.link}>
      <div className={styles.appCardContainer}>
        <div
          className={styles.logoBackground}
          style={{ backgroundColor: props.logoBackgroundColor }}
        >
          <img src={props.imgSrc} className={styles.appLogo} />
        </div>
        <span className={styles.appName}>{props.name}</span>
        <span className={styles.reviewStars}>
          ★★★★★ <span className={styles.reviewText}>{props.reviewRating}</span>
        </span>
        <span className={styles.appDescription}>{props.description}</span>
        {props.isCompleted ? (
          <div
            className={[styles.ctaBanner, styles.bottomCTACompleted].join(" ")}
          >
            Completed
          </div>
        ) : (
          <div
            className={[styles.ctaBanner, styles.bottomCTAIncomplete].join(" ")}
          >
            Learn more ➜
          </div>
        )}
      </div>
    </a>
  );
};

export default AppCard;
