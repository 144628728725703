import { useShopId } from "core/firebase/hooks/useShopId";
import { useEffect } from "react";
import { initializeHotjar } from "./hotjar";

declare global {
  var hj: HotJar;
}

export function useHotJar() {
  const shopId = useShopId();

  useEffect(() => {
    if (!shopId) return;
    initializeHotjar(2514649, 6);
    window.hj("identify", shopId, {});
  }, [shopId]);
}

type HotJar = (
  key: string,
  userId: string,
  userProperties: { [key: string]: any }
) => void;
