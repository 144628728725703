import PreviewOfferLink from "components/PreviewOfferLink";
import { getAnyOffer } from "core/firebase/firestore/client/onboarding/read";
import { useFirestore } from "core/firebase/hooks/useFirestore";
import { useShopId } from "core/firebase/hooks/useShopId";
import { Offer } from "core/types";
import { useRouter } from "next/router";
import { routes } from "pages/_app";
import { useEffect } from "react";
import { useState } from "react";
import styles from "./Onboarding.module.css";
import { OnboardingStep } from "./steps";

export const OnboardingSteps = (props: OnboardingStepsProps) => {
  const activeStep = props.step;
  const router = useRouter();
  const firestore = useFirestore();
  const shopId = useShopId();
  const [offerToPreview, setOfferToPreview] = useState<Offer | null>(null);

  useEffect(() => {
    if (activeStep !== OnboardingStep.previewOffer) return;
    if (!firestore || !shopId) return;
    let cancelled = false;
    getAnyOffer({ firestore, shopId }).then((offer) => {
      if (cancelled || !offer) return;
      setOfferToPreview(offer);
    });
    return () => {
      cancelled = true;
    };
  }, [firestore, shopId, activeStep]);

  const previewStep = (
    <Step
      text="Preview upsell on your storefront"
      step={OnboardingStep.previewOffer}
      activeStep={activeStep}
    />
  );

  return (
    <div className={styles.stepsContainer}>
      <div className={styles.stepsHeaderBar}>
        <h2 className={styles.stepsHeaderText}>
          {getHeaderText({ activeStep })}
        </h2>
      </div>
      <div className={getBodyClassName({ activeStep })}>
        <Step
          text="Create your first upsell"
          step={OnboardingStep.createFirstOffer}
          activeStep={activeStep}
          onClick={() => router.push(routes.builderNew)}
        />
        <div className={styles.divider} />
        {activeStep === OnboardingStep.previewOffer &&
        offerToPreview !== null ? (
          <PreviewOfferLink offer={offerToPreview}>
            {previewStep}
          </PreviewOfferLink>
        ) : (
          previewStep
        )}
        <div className={styles.divider} />
        <Step
          text="Activate upsell to start converting customers!"
          step={OnboardingStep.activateOffer}
          activeStep={activeStep}
          onClick={() => router.push(routes.offers)}
        />
      </div>
    </div>
  );
};

function getBodyClassName({ activeStep }: { activeStep: OnboardingStep }) {
  const classNames = [styles.stepsBody];
  if (activeStep === OnboardingStep.done) {
    classNames.push(styles.stepsBodyComplete);
  }
  return classNames.join(" ");
}

function getHeaderText({ activeStep }: { activeStep: OnboardingStep }): string {
  if (activeStep === OnboardingStep.done) {
    return "Enjoy UpCart!";
  }
  return "Steps to get started";
}

function stepIsComplete({
  activeStep,
  stepToCheck,
}: {
  activeStep: OnboardingStep;
  stepToCheck: OnboardingStep;
}): boolean {
  return activeStep > stepToCheck;
}

const Step = (props: StepProps) => {
  const isClickable = props.activeStep === props.step;
  return (
    <div
      className={getStepClassName({
        isClickable,
      })}
      onClick={isClickable ? props.onClick : () => {}}
    >
      {stepIsComplete({
        activeStep: props.activeStep,
        stepToCheck: props.step,
      }) ? (
        <Complete />
      ) : (
        <Incomplete />
      )}
      <div className={styles.stepText}>{props.text}</div>
    </div>
  );
};

function getStepClassName({ isClickable }: { isClickable: boolean }): string {
  const classNames = [styles.stepRow];
  if (isClickable) {
    classNames.push(styles.stepRowClickable);
  }
  return classNames.join(" ");
}

const Incomplete = () => {
  return <div className={styles.incompleteCircle} />;
};

const Complete = () => {
  return (
    <div className={styles.completeCircle}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.99997 18C6.86864 18.0001 6.73857 17.9743 6.61724 17.924C6.49591 17.8737 6.38571 17.8 6.29297 17.707L0.292971 11.707C0.200127 11.6141 0.126478 11.5039 0.076231 11.3826C0.0259839 11.2613 0.000122072 11.1313 0.00012207 11C0.000122068 10.8687 0.0259839 10.7387 0.076231 10.6174C0.126478 10.4961 0.200126 10.3858 0.292971 10.293C0.385816 10.2001 0.496038 10.1265 0.617345 10.0762C0.738653 10.026 0.868669 10.0001 0.999971 10.0001C1.13127 10.0001 1.26129 10.026 1.3826 10.0762C1.5039 10.1265 1.61413 10.2001 1.70697 10.293L6.94297 15.529L18.241 2.34899C18.4134 2.14769 18.6587 2.02312 18.9229 2.00267C19.1871 1.98223 19.4487 2.0676 19.65 2.23999C19.8513 2.41238 19.9758 2.65767 19.9963 2.92191C20.0167 3.18615 19.9314 3.44769 19.759 3.64899L7.75897 17.649C7.66943 17.7539 7.55915 17.8391 7.43507 17.8994C7.31099 17.9596 7.17579 17.9935 7.03797 17.999L6.99997 18Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

interface StepProps {
  text: string;
  step: OnboardingStep;
  activeStep: OnboardingStep;
  onClick?: () => void;
}

export interface OnboardingStepsProps {
  step: OnboardingStep;
}
