import styles from "./Chart.module.css";
import { ChartTabData, ChartTabs, useChartTabs } from "./tabs";
import { DropdownDatePicker as DropdownDatePicker } from "./DatePicker";
import { Graph } from "./Graph";
import React, { useEffect, useState } from "react";
import { fetchGraphData } from "./fetch/fetch";
import { useFirestore } from "core/firebase/hooks/useFirestore";
import { useCurrentUser } from "core/firebase/hooks/useCurrentUser";
import { ChartTabKey } from "./types";

const Chart = () => {
  const [selectedDateRange, setSelectedDateRange] = useState(getInitialDates);
  const [activeTabKey, setActiveTabKey] = useState(ChartTabKey.revenue);
  const [data, setData] = useState<{ x: Date; y: number }[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const firestore = useFirestore();
  const user = useCurrentUser();
  const tabs = useChartTabs();

  function handleClick(newActiveKey: ChartTabKey) {
    setActiveTabKey(newActiveKey);
  }

  useEffect(() => {
    if (!user || !firestore) return;
    let isCancelled = false;
    setIsLoading(true);
    fetchGraphData({
      tab: activeTabKey,
      firestore,
      shopId: user.uid,
      dateRange: selectedDateRange,
    })
      .then((newData) => {
        if (!isCancelled) {
          setData(newData);
        }
      })
      .catch((e) => {
        console.error(e);
        setData([]);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return () => {
      isCancelled = true;
    };
  }, [user, firestore, activeTabKey, selectedDateRange]);

  return (
    <div>
      <div className={styles.chartHeader}>
        <ChartTabs
          handleClick={handleClick}
          activeTabKey={activeTabKey}
          tabs={tabs}
        />
        <DropdownDatePicker
          selectedDateRange={selectedDateRange}
          setSelectedDateRange={setSelectedDateRange}
        />
      </div>
      <Graph
        yLabel={yLabelFromKey(activeTabKey, tabs)}
        isLoading={isLoading}
        data={data}
        dateRange={selectedDateRange}
      />
    </div>
  );
};

function getInitialDates() {
  var now = new Date();
  var currYear = now.getFullYear();
  var currMonth = now.getMonth();
  var currDay = now.getDate();
  return {
    start: new Date(`${currYear}/${currMonth}/${currDay}`),
    end: new Date(),
  };
}

function yLabelFromKey(tabKey: ChartTabKey, tabs: ChartTabData[]) {
  for (const tab of tabs) {
    if (tab.tabKey === tabKey) {
      return tab.yLabel;
    }
  }
  return "";
}

export default Chart;
