import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import Button, { ButtonTypes } from "components/Button";
import Card from "components/Card";
import { useRouter } from "next/router";
import { routes } from "pages/_app";
import styles from "./Analytics.module.css";
import Chart from "./Chart";
import Stats from "./stats";
import TopOffersTable from "./TopOffersTable";

const Analytics = () => {
  const router = useRouter();
  return (
    <div>
      <div className={styles.sectionHeading}>
        <div>
          <h2>Analytics</h2>
          <h5>View how your upsell offers are doing</h5>
        </div>
        <Button
          version={ButtonTypes.primary}
          text="Create Upsell"
          width="195px"
          icon={CirclePlusOutlineMinor}
          onClick={() => router.push(routes.builderNew)}
        />
      </div>

      <Stats />
      <Card>
        <h2>Performance</h2>
        <Chart />
      </Card>

      {false && (
        <>
          <h3 className={styles.upsellsTitle}>Top Performing Upsell Offers</h3>
          <TopOffersTable />
        </>
      )}
    </div>
  );
};

export default Analytics;
