import styles from "../Chart.module.css";
import Button, { ButtonTypes } from "components/Button";
import React, { useEffect, useState, useRef } from "react";
import { getPreviousMonthAndYear, toNiceDateString } from "./utils";
import { DateSelectorCard as DateSelectorCard } from "./card";
import { DateRangeSelections } from "./dropdown";

export const DropdownDatePicker = (props: DropdownDatePickerProps) => {
  var now = new Date();
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef<HTMLDivElement>(null);
  const [dateRangeText, setDateRangeText] = useState(
    DateRangeSelections.last30days
  );
  const [dateRangeOnButton, setDateRangeOnButton] = useState(dateRangeText);
  const [calendarFocusDate, setCalendarFocusDate] = useState(
    getPreviousMonthAndYear(now)
  );

  useEffect(() => {
    if (dateRangeText === DateRangeSelections.custom) {
      setDateRangeOnButton(
        [
          toNiceDateString(props.selectedDateRange.start),
          toNiceDateString(props.selectedDateRange.end),
        ].join(" - ")
      );
    } else {
      setDateRangeOnButton(dateRangeText);
    }
  }, [props.selectedDateRange, dateRangeText]);

  useEffect(() => {
    function handleClick(event: globalThis.MouseEvent) {
      if (showPopup) {
        if (
          popupRef.current !== null &&
          !popupRef.current.contains(event.target as Node)
        ) {
          setShowPopup(false);
        }
      }
    }

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [showPopup, popupRef]);

  function triggerPopup() {
    setShowPopup((curr) => !curr);
  }

  return (
    <div style={{ position: "relative" }}>
      {showPopup && (
        <div className={styles.popupContainer} ref={popupRef}>
          <DateSelectorCard
            selectedDates={props.selectedDateRange}
            setSelectedDates={props.setSelectedDateRange}
            dateRangeText={dateRangeText}
            setDateRangeText={setDateRangeText}
            onDateClick={(date) => {
              props.setSelectedDateRange(date);
              setDateRangeText(DateRangeSelections.custom);
            }}
            calendarFocusDate={calendarFocusDate}
            setCalendarFocusDate={setCalendarFocusDate}
          />
        </div>
      )}
      <Button
        version={ButtonTypes.secondary}
        text={dateRangeOnButton}
        onClick={triggerPopup}
        isDropdown
      />
    </div>
  );
};

interface DropdownDatePickerProps {
  selectedDateRange: {
    start: Date;
    end: Date;
  };
  setSelectedDateRange: React.Dispatch<
    React.SetStateAction<{
      start: Date;
      end: Date;
    }>
  >;
}
