import Scaffold from "components/Scaffold";
import Onboarding from "modules/Onboarding";
import Analytics from "modules/Analytics";
import { useHotJar } from "components/HotJar/useHotJar";

export default function Home() {
  useHotJar();

  return (
    <Scaffold>
      <Onboarding />
      <Analytics />
    </Scaffold>
  );
}
