import styles from "../Chart.module.css";
import {
  LineMarkSeries,
  FlexibleXYPlot,
  XAxis,
  YAxis,
  HorizontalGridLines,
} from "react-vis";
import { useEffect, useState } from "react";
import { sleep } from "core/utils";

const N_POINTS = 18;
const MIN_VAL = 1;
const MAX_VAL = 9;
const Y_LIMITS = [0, 10];
const N_GAUSS_ITERS = 4;

const LoadingGraph = () => {
  const [data, setData] = useState(
    dataFromArray(randomData(N_POINTS, MIN_VAL, MAX_VAL))
  );
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  async function runChartAnimator(state: { cancelled: boolean }) {
    await sleep(100);
    while (!state.cancelled) {
      setData(dataFromArray(randomData(N_POINTS, MIN_VAL, MAX_VAL)));
      await sleep(gaussianRandom(N_GAUSS_ITERS, 100, 800));
    }
  }
  useEffect(() => {
    let state = { cancelled: false };
    setIsFirstLoad(false);
    runChartAnimator(state);
    return () => {
      state.cancelled = true;
    };
  }, []);

  return (
    <div className={[styles.graphContainer, styles.stacker].join(" ")}>
      <FlexibleXYPlot className={styles.stacked} yDomain={Y_LIMITS}>
        <LineMarkSeries
          data={data}
          color="#e4e4e4"
          lineStyle={{ strokeWidth: "2px" }}
          markStyle={{ stroke: "white", strokeWidth: "1.5px" }}
          animation={isFirstLoad ? undefined : "wobbly"}
          curve={"curveMonotoneX"}
        />
        <HorizontalGridLines tickValues={[]} />
        <XAxis tickValues={[]} />
        <YAxis tickValues={[]} />
      </FlexibleXYPlot>
      <span className={styles.loadingText}>Loading...</span>
    </div>
  );
};

function dataFromArray(arr: number[]) {
  return arr.map((val, i) => {
    return { x: i, y: val };
  });
}

function randomData(nPoints: number, minVal: number, maxVal: number) {
  const data = [];
  for (var i = 0; i < nPoints; i++) {
    data.push(Math.random() * (maxVal - minVal) + minVal);
  }
  return data;
}

function gaussianRandom(nIterations: number, min: number, max: number) {
  var val = 0;
  for (var i = 0; i < nIterations; i++) {
    val += Math.random() * (max - min) + min;
  }
  return val / nIterations;
}

export default LoadingGraph;
