import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import Button, { ButtonTypes } from "components/Button";
import styles from "./Onboarding.module.css";
import Card from "components/Card";
import { useRouter } from "next/router";
import { routes } from "pages/_app";
import { OnboardingSteps } from "./OnboardingSteps";
import { useOnboardingStep } from "core/firebase/hooks/useOnboardingStep";
import { OnboardingStep } from "./steps";
import { useShopInfo } from "components/Scaffold/useShopInfo";
import AppCardList from "components/AppCardList";

const DemoVideoAndSteps = ({ step }: { step: OnboardingStep | null }) => {
  return (
    <Card style={{ paddingTop: "20px" }}>
      <h2>Get Started</h2>
      <h5>Watch this video to learn how to use UpCart</h5>
      <div className={styles.getStartedBody}>
        <iframe
          src="https://www.youtube.com/embed/QiMu4Dhomy4"
          title="YouTube video player"
          className={styles.video}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen={true}
        />
        {step !== null && <OnboardingSteps step={step} />}
      </div>
    </Card>
  );
};

const GreetingAndCreateOffer = ({ step }: { step: OnboardingStep | null }) => {
  const router = useRouter();
  const merchantName = useMerchantFirstName();

  return (
    <div className={styles.greetingContainer}>
      <div>
        <h2>Welcome {merchantName} 👋</h2>
        <h5>Let's help increase your sales with in cart upsell offers</h5>
      </div>
      <div>
        {step === OnboardingStep.createFirstOffer && (
          <Button
            onClick={() => router.push(routes.builderNew)}
            version={ButtonTypes.primary}
            icon={CirclePlusOutlineMinor}
            text="Create First Upsell"
            width="214px"
          />
        )}
      </div>
    </div>
  );
};

function useMerchantFirstName() {
  const { shopInfo } = useShopInfo();
  const merchantName = shopInfo?.shopOwner.split(" ")[0];
  return merchantName ? merchantName : "";
}

const Onboarding = () => {
  const onboardingStep = useOnboardingStep();
  const shouldShowOnboarding =
    onboardingStep !== null && onboardingStep !== OnboardingStep.hide;

  if (!shouldShowOnboarding) {
    return null;
  }
  return (
    <div className={styles.onboarding}>
      <GreetingAndCreateOffer step={onboardingStep} />
      <AppCardList />
      <DemoVideoAndSteps step={onboardingStep} />
    </div>
  );
};

export default Onboarding;
