import React from "react";

export interface IconProps {
  className: string;
}

export const DollarSmallIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="14"
      height="13.5"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.81014 14.2158C3.97128 14.2158 0.848114 11.1925 0.848114 7.47631C0.848114 3.76015 3.97128 0.736816 7.81014 0.736816C11.649 0.736816 14.7722 3.76015 14.7722 7.47631C14.7722 11.1925 11.649 14.2158 7.81014 14.2158ZM8.50634 4.10656C8.50634 3.73435 8.19464 3.43261 7.81014 3.43261C7.42564 3.43261 7.11394 3.73435 7.11394 4.10656V4.22154C6.3026 4.499 5.72153 5.24782 5.72153 6.12841C5.72153 6.81142 5.97202 7.3212 6.43514 7.65744C6.81127 7.93052 7.28085 8.04344 7.59527 8.11905L7.64129 8.13014C8.02429 8.22283 8.22251 8.28116 8.3497 8.37351C8.40873 8.41636 8.50634 8.49629 8.50634 8.82421C8.50634 9.19303 8.20107 9.49219 7.82153 9.49807C7.81558 9.4976 7.80452 9.49656 7.78894 9.4944C7.74937 9.48893 7.68177 9.47644 7.59517 9.4485C7.4244 9.3934 7.17307 9.27625 6.91002 9.02161C6.63814 8.75841 6.19733 8.75841 5.92544 9.02161C5.65356 9.2848 5.65356 9.71152 5.92544 9.97472C6.34195 10.3779 6.7693 10.5967 7.11394 10.7137V10.8461C7.11394 11.2183 7.42564 11.52 7.81014 11.52C8.19464 11.52 8.50634 11.2183 8.50634 10.8461V10.7311C9.31768 10.4536 9.89875 9.70481 9.89875 8.82421C9.89875 8.1412 9.64826 7.63143 9.18514 7.29519C8.80901 7.02211 8.33944 6.90919 8.02502 6.83358L7.97899 6.82249C7.59599 6.72979 7.39777 6.67146 7.27058 6.57912C7.21155 6.53626 7.11394 6.45633 7.11394 6.12841C7.11394 5.75959 7.41921 5.46043 7.79875 5.45455C7.80469 5.45502 7.81576 5.45607 7.83134 5.45822C7.87091 5.46369 7.93851 5.47618 8.02511 5.50412C8.19588 5.55923 8.4472 5.67637 8.71025 5.93102C8.98214 6.19421 9.42295 6.19421 9.69483 5.93102C9.96672 5.66782 9.96672 5.2411 9.69483 4.97791C9.27833 4.57472 8.85098 4.35596 8.50634 4.23894V4.10656Z"
      />
    </svg>
  );
};

export const CartSmallIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="15"
      height="13"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M9.11392 1.3546C9.11392 1.01341 8.80222 0.736816 8.41772 0.736816C8.03322 0.736816 7.72152 1.01341 7.72152 1.3546V3.56986L6.8214 2.77112C6.54952 2.52986 6.10871 2.52986 5.83682 2.77112C5.56494 3.01238 5.56494 3.40355 5.83682 3.64481L7.92543 5.49817C8.19732 5.73943 8.63813 5.73943 8.91001 5.49817L10.9986 3.64481C11.2705 3.40355 11.2705 3.01238 10.9986 2.77112C10.7267 2.52986 10.2859 2.52986 10.014 2.77112L9.11392 3.56986V1.3546Z" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.4557 1.3546C1.4557 1.01341 1.7674 0.736816 2.1519 0.736816H3.1962C3.77295 0.736816 4.24051 1.1517 4.24051 1.6635V6.91469H11.9911L12.6019 3.1206C12.6563 2.78283 13.009 2.54813 13.3896 2.59639C13.7702 2.64464 14.0347 2.95757 13.9803 3.29533L13.3268 7.35463C13.2533 7.81116 12.8127 8.15026 12.293 8.15026H4.24051V9.38584H11.2025C12.356 9.38584 13.2911 10.2156 13.2911 11.2392C13.2911 12.2628 12.356 13.0926 11.2025 13.0926C10.049 13.0926 9.11392 12.2628 9.11392 11.2392C9.11392 11.0226 9.1558 10.8146 9.23277 10.6214H5.51407C5.59103 10.8146 5.63291 11.0226 5.63291 11.2392C5.63291 12.2628 4.69781 13.0926 3.5443 13.0926C2.3908 13.0926 1.4557 12.2628 1.4557 11.2392C1.4557 10.4322 2.03689 9.74572 2.8481 9.4913V1.97239H2.1519C1.7674 1.97239 1.4557 1.6958 1.4557 1.3546ZM10.5063 11.2392C10.5063 10.898 10.818 10.6214 11.2025 10.6214C11.587 10.6214 11.8987 10.898 11.8987 11.2392C11.8987 11.5804 11.587 11.857 11.2025 11.857C10.818 11.857 10.5063 11.5804 10.5063 11.2392ZM2.8481 11.2392C2.8481 10.898 3.1598 10.6214 3.5443 10.6214C3.92881 10.6214 4.24051 10.898 4.24051 11.2392C4.24051 11.5804 3.92881 11.857 3.5443 11.857C3.1598 11.857 2.8481 11.5804 2.8481 11.2392Z"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="13.9241"
            height="12.3557"
            fill="white"
            transform="translate(0.759491 0.736816)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const OrdersSmallIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="11"
      height="11.5"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M6.44388 1.33682C6.44388 1.00545 6.17525 0.736816 5.84388 0.736816C5.51251 0.736816 5.24388 1.00545 5.24388 1.33682V5.88829L4.46814 5.11255C4.23383 4.87824 3.85393 4.87824 3.61961 5.11255C3.3853 5.34687 3.3853 5.72677 3.61961 5.96108L5.41961 7.76108C5.53213 7.8736 5.68475 7.93682 5.84388 7.93682C6.00301 7.93682 6.15562 7.8736 6.26814 7.76108L8.06814 5.96108C8.30245 5.72677 8.30245 5.34687 8.06814 5.11255C7.83382 4.87824 7.45392 4.87824 7.21961 5.11255L6.44388 5.88828V1.33682Z" />
      <path d="M1.64388 9.13682V2.53682H4.04388V1.33682H1.34388C0.846822 1.33682 0.443878 1.73976 0.443878 2.23682V11.2368C0.443878 11.7339 0.846822 12.1368 1.34388 12.1368H10.3439C10.8409 12.1368 11.2439 11.7339 11.2439 11.2368V2.23682C11.2439 1.73976 10.8409 1.33682 10.3439 1.33682H7.64388C7.64388 1.73682 7.64388 2.13682 7.64388 2.53682H10.0439V9.13682H7.94388C7.47901 9.13682 7.11127 9.5342 6.78837 9.88313C6.76602 9.90729 6.74388 9.93121 6.72194 9.95476C6.50287 10.1898 6.19054 10.3368 5.84388 10.3368C5.49722 10.3368 5.18489 10.1898 4.96582 9.95476C4.94388 9.93121 4.92174 9.90729 4.89938 9.88313C4.57649 9.5342 4.20875 9.13682 3.74388 9.13682H1.64388Z" />
    </svg>
  );
};

export const CustomerSmallIcon = ({ className }: IconProps) => {
  return (
    <svg
      className={className}
      width="11.5"
      height="12"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10.2357 4.2544C10.2357 5.82483 8.92059 7.09791 7.29831 7.09791C5.67602 7.09791 4.36091 5.82483 4.36091 4.2544C4.36091 2.68397 5.67602 1.41089 7.29831 1.41089C8.92059 1.41089 10.2357 2.68397 10.2357 4.2544Z" />
      <path d="M2.09484 10.4883C3.05917 9.75412 4.97833 8.56583 7.29831 8.56583C9.61829 8.56583 11.5374 9.75412 12.5018 10.4883C12.9322 10.816 13.0665 11.37 12.8965 11.8739L12.6646 12.5615C12.4673 13.1463 11.9039 13.542 11.2684 13.542H3.3282C2.69271 13.542 2.1293 13.1463 1.93205 12.5615L1.7001 11.8739C1.53012 11.37 1.6644 10.816 2.09484 10.4883Z" />
    </svg>
  );
};
