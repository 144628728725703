import { useMoneyFormat } from "core/useMoneyFormat";
import styles from "./Chart.module.css";
import { ChartTabKey } from "./types";
import {
  DollarSmallIcon,
  CartSmallIcon,
  OrdersSmallIcon,
  CustomerSmallIcon,
} from "./icons";
import { getMoneyString } from "core/money";
import { useMemo } from "react";

const ChartTab = ({ iconBuilder, name, isActive, onClick }: ChartTabProps) => {
  return (
    <div
      className={
        isActive
          ? [styles.chartSelectorItem, styles.activeItem].join(" ")
          : styles.chartSelectorItem
      }
      onClick={onClick}
    >
      {iconBuilder({
        className: (isActive
          ? [styles.chartSelectorIcon, styles.activeIcon].join(" ")
          : styles.chartSelectorIcon) as string,
      })}
      {name}
    </div>
  );
};

export function ChartTabs({
  handleClick,
  activeTabKey,
  tabs,
}: {
  handleClick: (newActiveKey: ChartTabKey) => void;
  activeTabKey: ChartTabKey;
  tabs: ChartTabData[];
}) {
  return (
    <div className={styles.chartTabsContainer}>
      {tabs.map((tab) => (
        <ChartTab
          iconBuilder={tab.iconBuilder}
          name={tab.name}
          onClick={() => handleClick(tab.tabKey)}
          tabKey={tab.tabKey}
          isActive={tab.tabKey === activeTabKey}
          key={tab.tabKey}
          yLabel={tab.yLabel}
        />
      ))}
    </div>
  );
}

export function useChartTabs(): ChartTabData[] {
  const moneyFormat = useMoneyFormat();

  const tabs = useMemo(
    () => [
      {
        tabKey: ChartTabKey.revenue,
        name: "Revenue",
        yLabel: `Revenue (${getMoneyString({ moneyFormat, value: null })})`,
        iconBuilder: DollarSmallIcon,
      },
      {
        tabKey: ChartTabKey.orders,
        name: "Orders",
        yLabel: "Orders",
        iconBuilder: OrdersSmallIcon,
      },
      {
        tabKey: ChartTabKey.impressions,
        name: "Impressions",
        yLabel: "Impressions",
        iconBuilder: CustomerSmallIcon,
      },
      {
        tabKey: ChartTabKey.conversionRate,
        name: "Conversion Rate",
        yLabel: "Conversion Rate (%)",
        iconBuilder: CartSmallIcon,
      },
    ],
    [moneyFormat]
  );

  return tabs;
}

export interface ChartTabData {
  tabKey: ChartTabKey;
  name: string;
  iconBuilder: ({ className }: { className: string }) => React.ReactNode;
  isActive?: boolean;
  yLabel: string;
}

export interface ChartTabProps extends ChartTabData {
  onClick: () => void;
}
