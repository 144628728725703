import styles from "../Chart.module.css";

export const EmptyGraph = () => {
  return (
    <div className={styles.emptyGraphContainer}>
      <ChartIcon />
      <div className={styles.emptyGraphText}>
        Nothing to show here yet, try changing the time scale or check back
        later!
      </div>
    </div>
  );
};

const ChartIcon = () => {
  return (
    <svg
      width="91"
      height="85"
      viewBox="0 0 91 85"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M76.7812 0C72.0696 0 68.25 3.80558 68.25 8.5V76.5C68.25 81.1944 72.0696 85 76.7812 85H82.4688C87.1804 85 91 81.1944 91 76.5V8.5C91 3.80558 87.1804 0 82.4688 0H76.7812Z"
        fill="#DEE1F3"
      />
      <path
        d="M34.125 31.1667C34.125 26.4722 37.9446 22.6667 42.6562 22.6667H48.3438C53.0554 22.6667 56.875 26.4722 56.875 31.1667V76.5C56.875 81.1944 53.0554 85 48.3438 85H42.6562C37.9446 85 34.125 81.1944 34.125 76.5V31.1667Z"
        fill="#DEE1F3"
      />
      <path
        d="M0 53.8333C0 49.1389 3.81957 45.3333 8.53125 45.3333H14.2188C18.9304 45.3333 22.75 49.1389 22.75 53.8333V76.5C22.75 81.1944 18.9304 85 14.2188 85H8.53125C3.81957 85 0 81.1944 0 76.5V53.8333Z"
        fill="#DEE1F3"
      />
    </svg>
  );
};
