import React from "react";
import styles from "./Card.module.scss";

export interface CardProps extends React.ComponentProps<"div"> {}

const Card = (props: CardProps) => {
  const { children, style } = props;
  return (
    <div className={styles.card} style={style}>
      {children}
    </div>
  );
};

export default Card;
