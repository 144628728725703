import styles from "./TopOffersTable.module.css";
import { useEffect, useState } from "react";
import { SortableTable } from "components/Tables";
import {
  SortableHeaderItemData,
  SortDirection,
  TableBodyData,
  AllowedSortDirections,
} from "components/Tables/types";
import { getTopOffersStats } from "core/firebase/firestore/client/offers/read";
import { OfferStats } from "core/types";
import Button, { ButtonTypes } from "components/Button";
import { CirclePlusOutlineMinor } from "@shopify/polaris-icons";
import { useRouter } from "next/router";
import { routes } from "pages/_app";
import { numToNiceStr } from "core/strings";
import { useFirestore } from "core/firebase/hooks/useFirestore";

const TopOffersTable = () => {
  const [sort, setSort] = useState<OfferTableSort>(INITIAL_SORT);
  const [data, setData] = useState<TableBodyData>(loadingData);
  const firestore = useFirestore();
  function handleSortCallback(
    sortDirection: SortDirection,
    dataKey: string | number
  ) {
    setSort({
      sortDirection: sortDirection,
      keyOfSortedColumn: dataKey,
    });
  }

  useEffect(() => {
    if (!firestore) return;
    setData(loadingData);
    let isCancelled = false;
    getTopOffersStats({ firestore, nToGet: 3 })
      .then((offersStats) => {
        if (isCancelled) return;
        const elements = offersStats.map((offerStats) =>
          offerToElements(offerStats)
        );
        setData(elements);
      })
      .catch((e) => {
        console.log(e);
      });
    return () => {
      isCancelled = true;
    };
  }, [sort]);

  return (
    <>
      <SortableTable
        header={header}
        data={data}
        initialSortDetails={INITIAL_SORT}
        onSortCallback={handleSortCallback}
      />
      <TopOffersTableFooter />
    </>
  );
};

const TopOffersTableFooter = () => {
  const router = useRouter();
  return (
    <div className={styles.footer}>
      <Button
        version={ButtonTypes.secondary}
        text="See all Upsells"
        width="170px"
        onClick={() => router.push(routes.offers)}
      />
      <Button
        version={ButtonTypes.primary}
        text="Create Upsell"
        width="195px"
        icon={CirclePlusOutlineMinor}
        onClick={() => router.push(routes.builderNew)}
      />
    </div>
  );
};

const loadingData = [{}, {}, {}];

const INITIAL_SORT: OfferTableSort = {
  sortDirection: SortDirection.DESCENDING,
  keyOfSortedColumn: "revenue",
};

interface OfferTableSort {
  sortDirection: SortDirection;
  keyOfSortedColumn: string | number;
}

function offerToElements(offerStats: OfferStats) {
  return {
    chosenId: offerStats.offerChosenId,
    revenue: `$${numToNiceStr(offerStats.revenue, 2)}`,
    ordersCount: `${numToNiceStr(offerStats.ordersCount, 0)}`,
    impressions: `${numToNiceStr(offerStats.impressions, 0)}`,
    conversionRate: `${numToNiceStr(offerStats.conversionRate, 1)}%`,
  };
}

const header: SortableHeaderItemData[] = [
  {
    title: "Offer ID",
    dataKey: "chosenId",
    allowedSortDirections: AllowedSortDirections.NEITHER,
  },
  {
    title: "Revenue",
    dataKey: "revenue",
    allowedSortDirections: AllowedSortDirections.DECENDING_ONLY,
  },
  {
    title: "Orders",
    dataKey: "ordersCount",
    allowedSortDirections: AllowedSortDirections.DECENDING_ONLY,
  },
  {
    title: "Impressions",
    dataKey: "impressions",
    allowedSortDirections: AllowedSortDirections.DECENDING_ONLY,
  },
  {
    title: "Conversion Rate",
    dataKey: "conversionRate",
    allowedSortDirections: AllowedSortDirections.DECENDING_ONLY,
  },
];

export default TopOffersTable;
